import React, { useEffect, useRef, useState } from 'react'
import {
  DocumentCheckIcon,
  ClipboardIcon,
  MicrophoneIcon
} from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'

const TextArea = ({ startingValue, onChange }) => {
  const textareaRef = useRef()
  const [value, setValue] = useState(startingValue)
  useEffect(() => {
    textareaRef.current.style.height = 'auto'
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
  }, [value])

  const handleValueChange = e => {
    setValue(e.target.value)
    onChange(e.target.value) // notify the parent about the value change
  }

  useEffect(() => {
    onChange(textareaRef) // pass back the ref to parent
  }, [])

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={handleValueChange}
      className='w-full h-auto'
    />
  )
}

export default function Output ({ outputs }) {
  const [copiedIndex, setCopiedIndex] = useState(null)
  const navigate = useNavigate()

  const [textareaValues, setTextareaValues] = useState(() =>
    outputs.map(() => '')
  ) // initial state

  const [textareaRefs, setTextareaRefs] = useState([])

  const handleRefChange = (ref, index) => {
    setTextareaRefs(refs => {
      const newRefs = [...refs]
      newRefs[index] = ref
      return newRefs
    })
  }

  useEffect(() => {
    const copyToClipboard = async index => {
      const textToCopy = textareaValues[index]
      if (textToCopy) {
        try {
          await navigator.clipboard.writeText(textToCopy)
          setCopiedIndex(index)
        } catch (err) {
          console.error('Could not copy text: ', err)
        }
      }
    }

    if (copiedIndex !== null) {
      copyToClipboard(copiedIndex)
    }
  }, [textareaValues, copiedIndex])

  const handleCopy = index => {
    const currentTextArea = textareaRefs[index]?.current
    if (currentTextArea && currentTextArea.value) {
      navigator.clipboard
        .writeText(currentTextArea.value)
        .then(() => setCopiedIndex(index))
        .catch(err => console.error('Could not copy text: ', err))
    }
  }

  const handleValueChange = (value, index) => {
    setTextareaValues(values => {
      const newValues = [...values]
      newValues[index] = value
      return newValues
    })
  }

  return (
    <>
      <div>
        <div className='flex flex-row items-center justify-between bg-gray-800 shadow-md p-3'>
          <div className='min-w-0 py-2'>
            <h2 className='text-xl font-bold leading-7 text-white sm:truncate sm:text-3xl sm:tracking-tight'>
              Outputs
            </h2>
          </div>
          <div className='py-2 flex space-x-2 justify-center'>
            <button
              type='button'
              onClick={() => navigate('/record')}
              className='inline-flex items-center px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            >
              <MicrophoneIcon
                className='-ml-1 mr-2 h-5 w-5'
                aria-hidden='true'
              />
              New Recording
            </button>
          </div>
        </div>

        <main className='main-content flex flex-col mt-3 items-center px-3 sm:px-5 md:px-9 lg:px-16'>
          {outputs.map((output, index) => (
            <div
              key={index}
              className='flex flex-col items-center justify-center mt-3 w-full bg-white shadow rounded-lg'
            >
              <div className='flex bg-gray-200 w-full p-3 rounded-sm'>
                <p className='text-sm font-bold'>{output.output_type}</p>
                {copiedIndex === index ? (
                  <DocumentCheckIcon className='h-5 w-5 ml-auto text-green-600' />
                ) : (
                  <ClipboardIcon
                    className='h-5 w-5 ml-auto cursor-pointer hover:text-gray-600'
                    onClick={() => handleCopy(index)}
                  />
                )}
              </div>
              <div className='py-3 w-full px-3'>
                <TextArea
                  startingValue={output.completion}
                  onChange={ref => handleRefChange(ref, index)}
                />
              </div>
            </div>
          ))}
        </main>
      </div>
    </>
  )
}
