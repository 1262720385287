import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import LandingPage from './LandingPage'
import Record from './Record'
import Output from './Output'
import Metrics from './Metrics'
function App () {
  const [outputs, setOutputs] = useState([])
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route
          path='/record'
          element={
            <Record key={Math.random() * 1000} setOutputs={setOutputs} />
          }
        />
        <Route path='/output' element={<Output outputs={outputs} />} />
        <Route path='/axyqqq' element={<Metrics />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
