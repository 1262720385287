import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from './utils/api'
import Cookies from 'js-cookie'

export default function EmailCollectionPopup ({ setOpen }) {
  const [_, setNothing] = useState(null)
  const [email, setEmail] = useState('')
  async function addEmail () {
    try {
      const res = await axios
        .post('/addEmail', { email })
        .then(res => {
          Cookies.set('token', res.data.token)
        })
        .catch(err => console.log(err))
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as='div' className='relative z-100' onClose={setNothing}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0'>
            {' '}
            {/* Here I changed p-4 to p-2 */}
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full lg:max-w-lg sm:p-6'>
                <div>
                  <div className='text-center'>
                    <Dialog.Title
                      as='h3'
                      className='text-2xl font-semibold leading-6 text-gray-900'
                    >
                      Create Your Account
                    </Dialog.Title>
                    <div className='mt-2 text-left'>
                      <div>
                        <label
                          htmlFor='email'
                          className='block text-sm font-medium leading-6 text-gray-900'
                        >
                          Email
                        </label>
                        <div className='mt-2'>
                          <input
                            type='email'
                            name='email'
                            id='email'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                            placeholder='you@example.com'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    onClick={() => addEmail().then(() => setOpen(false))}
                  >
                    Let me in
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
