import { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  SparklesIcon,
  XMarkIcon,
  MicrophoneIcon
} from '@heroicons/react/24/outline'
import './Record.css'
import axios from './utils/api'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import EmailCollectionPopup from './EmailCollectionPopup'
import logo from './images/braindump-logo.png'
const _output_types = [
  { id: 1, name: '📝 Summary', checked: true },
  { id: 2, name: '📧 Cold Email', checked: false },
  { id: 3, name: '📮 Blog Post', checked: false },
  { id: 4, name: '💼 LinkedIn Post', checked: false },
  { id: 5, name: '📤 Email', checked: false },
  { id: 6, name: '📜 TikTok Script', checked: false },
  { id: 7, name: '📈 Elevator Pitch', checked: false },
  { id: 8, name: '📋 Reddit Post', checked: false },
  { id: 9, name: '🧵 Twitter Thread', checked: false },
  { id: 10, name: '💡 Ideas', checked: false }
]

function classNames (...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Record ({ setOutputs }) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [output_types, setOutputTypes] = useState(_output_types)
  const [showEmailPopup, setShowEmailPopup] = useState(false)
  const [responseLoading, setResponseLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [mediaURL, setMediaURL] = useState('')
  const mediaRecorderRef = useRef(null)
  const recordedChunksRef = useRef([])
  const streamRef = useRef(null) // Create a new ref to store the stream
  const [mimeUsed, setMimeType] = useState('.webm')

  const navigate = useNavigate()
  useEffect(() => {
    axios
      .post('/pageView', { page: 'main' })
      .then(res => {})
      .catch(err => {
        console.log(err)
      })
  }, [])

  async function generateOutput (mediaBlobUrl) {
    setResponseLoading(true)
    const response = await fetch(mediaBlobUrl)
    const blob = await response.blob()
    const file = new File([blob], `recorded_audio${mimeUsed}`, { type: blob.type }) // change the filetype according to your requirement

    const formData = new FormData()
    formData.append('file', file) // 'file' is the name of the form field to be sent to the server
    formData.append(
      'output_types',
      output_types
        .filter(output_type => output_type.checked)
        .map(output_type => output_type.name)
    )
    try {
      const result = await axios.post('/generateOutput', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      setResponseLoading(false)
      setOutputs(result.data)
      navigate('/output')
    } catch (error) {
      console.error('Error while sending file to server:', error)
    }
  }

  const startRecording = async () => {
    recordedChunksRef.current = []
    streamRef.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    })
    const mimeType = 'audio/webm'
    const fallbackMimeType = 'audio/mp4'

    if (!MediaRecorder.isTypeSupported(mimeType)) {
      if (!MediaRecorder.isTypeSupported(fallbackMimeType)) {
        console.error(
          'Both mimeType and fallbackMimeType are not supported by your browser'
        )
        return
      } else {
        mediaRecorderRef.current = new MediaRecorder(streamRef.current, {
          mimeType: fallbackMimeType
        })
        setMimeType('.mp4')
      }
    } else {
      mediaRecorderRef.current = new MediaRecorder(streamRef.current, {
        mimeType: mimeType
      })
    }

    mediaRecorderRef.current.addEventListener('dataavailable', function (e) {
      if (e.data.size > 0) recordedChunksRef.current.push(e.data)
    })

    mediaRecorderRef.current.addEventListener('stop', function () {
      const blob = new Blob(recordedChunksRef.current, {
        type: mediaRecorderRef.current.mimeType
      })
      const url = URL.createObjectURL(blob)
      setMediaURL(url)
    })

    setIsRecording(true)
    mediaRecorderRef.current.start()
  }

  const stopRecording = () => {
    setIsRecording(false)
    mediaRecorderRef.current.stop()
    // Stop the stream
    streamRef.current.getTracks().forEach(track => track.stop())
  }

  const handleRecording = () => {
    if (isRecording) {
      stopRecording()
    } else {
      setMediaURL('')
      startRecording()
    }
  }

  return (
    <>
      {showEmailPopup && <EmailCollectionPopup setOpen={setShowEmailPopup} />}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-50 lg:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-900/80' />
            </Transition.Child>

            <div className='fixed inset-0 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                      <button
                        type='button'
                        className='-m-2.5 p-2.5'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10'>
                    <div className='flex h-16 shrink-0 items-center'>
                      <img className='h-8 w-auto' src={logo} alt='BrainDump' />
                    </div>
                    <nav className='flex flex-1 flex-col'>
                      <fieldset>
                        <legend className='text-base font-semibold leading-6 text-white'>
                          Output types
                        </legend>
                        <div className='mt-4 divide-y divide-gray-200 border-b border-t border-gray-200'>
                          {output_types.map((outputType, outputIdx) => (
                            <div
                              key={outputIdx}
                              className='relative flex items-start py-4'
                            >
                              <div className='min-w-0 flex-1 text-sm leading-6'>
                                <label
                                  htmlFor={`person-${outputType.id}`}
                                  className='select-none font-medium text-white'
                                >
                                  {outputType.name}
                                </label>
                              </div>
                              <div className='ml-3 flex h-6 items-center'>
                                <input
                                  id={`person-${outputType.id}`}
                                  name={`person-${outputType.id}`}
                                  checked={outputType.checked}
                                  onChange={e => {
                                    const newOutputTypes = [...output_types]
                                    newOutputTypes[outputIdx].checked =
                                      e.target.checked
                                    setOutputTypes(newOutputTypes)
                                  }}
                                  type='checkbox'
                                  className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0  lg:flex lg:w-72 lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6'>
            <div className='flex h-16 shrink-0 items-center'>
              <img className='h-8 w-auto' src={logo} alt='BrainDump' />
            </div>
            <nav className='flex flex-1 flex-col'>
              <fieldset>
                <legend className='text-base font-semibold leading-6 text-white'>
                  Output types
                </legend>
                <div className='mt-4 divide-y divide-gray-200 border-b border-t border-gray-200'>
                  {output_types.map((outputType, outputIdx) => (
                    <div
                      key={outputIdx}
                      className='relative flex items-start py-4'
                    >
                      <div className='min-w-0 flex-1 text-sm leading-6'>
                        <label
                          htmlFor={`person-${outputType.id}`}
                          className='select-none font-medium text-white'
                        >
                          {outputType.name}
                        </label>
                      </div>
                      <div className='ml-3 flex h-6 items-center'>
                        <input
                          id={`person-${outputType.id}`}
                          name={`person-${outputType.id}`}
                          checked={outputType.checked}
                          onChange={e => {
                            const newOutputTypes = [...output_types]
                            newOutputTypes[outputIdx].checked = e.target.checked
                            setOutputTypes(newOutputTypes)
                          }}
                          type='checkbox'
                          className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </fieldset>
            </nav>
          </div>
        </div>

        <div className='sticky top-0 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden'>
          <button
            type='button'
            className='-m-2.5 p-2.5 text-gray-400 lg:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
          <div className='flex-1 text-sm font-semibold leading-6 text-white'>
            Record new brain dump
          </div>
        </div>

        <main className='main-content lg:pl-72 flex flex-col items-center justify-center'>
          <div className='w-full lg:hidden fixed top-16 mt-3 flex justify-center'>
            <span className='flex items-center justify-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20'>
              {`${
                output_types.filter(output_type => output_type.checked).length
              } output type${
                output_types.filter(output_type => output_type.checked).length >
                1
                  ? 's'
                  : ''
              } selected. `}
              <a
                onClick={() => setSidebarOpen(true)}
                className='ml-2 text-blue-600 hover:text-blue-800 transition-colors'
              >
                Add more →
              </a>
            </span>
          </div>
          <div className='px-4 sm:px-16 lg:px-32 flex flex-col items-center justify-center'>
            <div className='text-center'>
              <div className='flex justify-center'>
                <SparklesIcon className='h-16 w-16' aria-hidden='true' />
              </div>

              <h3 className='mt-5 text-sm font-semibold text-gray-900'>
                New brain dump
              </h3>

              <>
                {mediaURL ? (
                  <audio src={mediaURL} controls />
                ) : (
                  <p className='mt-3 text-sm text-gray-500'>
                    Start recording, voice your thoughts freely and at your own
                    pace. Afterward, our AI will transcribe and organize your
                    thoughts in the format you want.
                  </p>
                )}

                <div className='mt-6 flex space-x-2 justify-center'>
                  <button
                    type='button'
                    className={classNames(
                      responseLoading
                        ? 'cursor-not-allowed'
                        : isRecording
                        ? 'text-white animate-glow'
                        : 'bg-gray-50 text-gray-800 hover:bg-indigo-500 border-gray-500 border-2',
                      'inline-flex items-center px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                    onClick={handleRecording}
                  >
                    <MicrophoneIcon
                      className='-ml-1 mr-2 h-5 w-5'
                      aria-hidden='true'
                    />
                    {mediaURL
                      ? 'Replace'
                      : isRecording
                      ? 'Stop recording'
                      : 'Start recording'}
                  </button>
                  {mediaURL && (
                    <button
                      type='button'
                      onClick={() => generateOutput(mediaURL)}
                      className='inline-flex items-center px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    >
                      {responseLoading ? (
                        <div className='spinner'></div> // Replace this with your spinner component or classes
                      ) : (
                        'Next'
                      )}
                    </button>
                  )}
                </div>
              </>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
