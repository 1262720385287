import { useState, useEffect } from 'react'
import axios from './utils/api'
import Cookies from 'js-cookie'
import MetricAuth from './MetricAuth'

export default function Metrics () {
  const [stats, setStats] = useState([
    { name: 'Page Views', stat: '---' },
    { name: 'Total Transcriptions', stat: '---' }
  ])
  const [emails, setEmails] = useState([])
  const [needsAuth, setNeedsAuth] = useState(false)

  async function refresh () {
    const masterCookie = Cookies.get('master')

    if (masterCookie) {
      axios
        .get(`/metrics?c=${masterCookie}`)
        .then(response => {
          setStats(response.data.stats)
          setEmails(response.data.emails)
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      setNeedsAuth(true)
    }
  }
  useEffect(() => {
    const masterCookie = Cookies.get('master')

    if (masterCookie) {
      axios
        .get(`/metrics?c=${masterCookie}`)
        .then(response => {
          setStats(response.data.stats)
          setEmails(response.data.emails)
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      setNeedsAuth(true)
    }
  }, [])
  return (
    <>
      <MetricAuth open={needsAuth} setOpen={setNeedsAuth} />
      <div className='min-h-full'>
        <div className='py-10'>
          <header>
            <div className='flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 space-x-44'>
              <h1 className='text-3xl font-bold leading-tight tracking-tight text-gray-900'>
                Metrics
              </h1>
              <button
                type='button'
                onClick={refresh}
                className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Refresh
              </button>
            </div>
          </header>
          <main>
            <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
              <div>
                <dl className='mx-3 mt-5 grid grid-rows-3 gap-5 sm:grid-rows-3'>
                  {stats.map(item => (
                    <div
                      key={item.name}
                      className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow-md sm:p-6'
                    >
                      <dt className='truncate text-sm font-medium text-gray-500'>
                        {item.name}
                      </dt>
                      <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                        {item.stat}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
              <div className='px-4 sm:px-6 lg:px-8'>
                <div className='mx-3 mt-8 flow-root rounded-md'>
                  <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                    <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-gray-100'>
                            <tr>
                              <th
                                scope='col'
                                className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                              >
                                {`Emails (${emails.length} total)`}
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {emails.map(person => (
                              <tr key={person.email}>
                                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                  {person.email}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
