import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { NoSymbolIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import axios from './utils/api'
import Cookies from 'js-cookie'

export default function MetricAuth ({ open, setOpen }) {
  const navigate = useNavigate()
  const [password, setPassword] = useState('')
  const [wrongCounter, setWrongCounter] = useState(0)
  const [_, setNothing] = useState(true)
  async function handleAuth () {
    try {
      const res = await axios
        .post('/metrics-auth', { password })
        .then(res => {
          console.log(res)
          if (res.data.token) {
            Cookies.set('master', res.data.token)
            setOpen(false)
          } else {
            alert('Get the fuck out')
            setWrongCounter(wrongCounter + 1)
            if (wrongCounter > 3) {
              navigate('/')
            }
          }
        })
        .catch(err => {
          alert('Get the fuck out')
          setWrongCounter(wrongCounter + 1)
          if (wrongCounter > 3) {
            navigate('/')
          }
        })
    } catch (err) {}
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setNothing}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex items-center justify-center min-h-screen p-4'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                <div>
                  <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100'>
                    <NoSymbolIcon
                      className='h-6 w-6 text-red-600'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:mt-5'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      Authentication needed
                    </Dialog.Title>
                    <div className='mt-2'>
                      <div className='mt-2'>
                        <input
                          type='password'
                          name='email'
                          id='email'
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-6'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    onClick={handleAuth}
                  >
                    Login
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
