import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from './utils/api'
import logo from './images/braindump-logo.png'
import demo from './images/Demo.gif'
export default function LandingPage () {
  const navigate = useNavigate()

  // update landing page count when this page loads
  useEffect(() => {
    axios
      .post('/pageView', { page: 'landing' })
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])
  return (
    <div className='bg-white'>
      <header className='absolute inset-x-0 top-0 z-50'>
        <nav
          className='flex items-center justify-between p-6 lg:px-8'
          aria-label='Global'
        >
          <div className='flex lg:flex-1'>
            <a href='#' className='flex items-center'>
              <span>
                <img className='h-12 sm:h-16 w-auto' src={logo} alt='' />
              </span>
              <span className='ml-4 font-bold text-2xl'>BrainDump</span>
            </a>
          </div>
        </nav>
      </header>

      <div className='relative isolate px-6 pt-14 lg:px-8'>
        <div
          className='absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]'
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
            }}
          />
        </div>
        <div className='mx-auto max-w-2xl py-12 sm:py-16 lg:py-24'>
          {' '}
          {/* Adjust padding top to make header text closer to the top */}
          <div className='text-center'>
            <h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
              Your thoughts, organized by AI.
            </h1>
            <p className='mt-5 text-lg leading-8 text-gray-600'>
              Transcribe, Transform, and Format your Voice Notes Instantly.
              Simply speak your mind, and let BrainDump transcribe and format
              your words into well-written and polished content.
            </p>
            <div className='mt-3 mx-auto p-2 bg-gray-100 border-2 border-gray-400 shadow-lg rounded-lg'>
              {' '}
              {/* Background for the gif */}
              <img src={demo} alt='Demo' className='mx-auto w-full' />{' '}
              {/* Make the gif bigger */}
            </div>
            <div className='mt-10 flex items-center justify-center gap-x-6'>
              <a
                onClick={() => navigate('/record')}
                className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Try it out <span aria-hidden='true'>→</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className='absolute lg:hidden inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]'
          aria-hidden='true'
        >
          <div
            className='relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]'
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)'
            }}
          />
        </div>
      </div>
    </div>
  )
}
